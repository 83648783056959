jQuery(function () {
  const informations = $('#google_maps_data').val() || '{}'
  const parsedInfo = JSON.parse(informations)

  // Create the script tag, set the appropriate attributes
  var script = document.createElement('script')
  script.src = `https://maps.googleapis.com/maps/api/js?key=${parsedInfo.key}&callback=initMap&libraries=&v=weekly`
  script.defer = true

  // Attach your callback function to the `window` object
  window.initMap = initMap

  // Append the 'script' element to 'head'
  document.head.appendChild(script)
})

function initMap () {
  const svgMarker = '<svg viewBox="448 311 24 32" xmlns="http://www.w3.org/2000/svg"><path d="M 459.66926 341.3333 C 459.66926 341.3333 470.33466 328.5 470.33466 323 C 470.33466 317.5 466.77865 312 459.66926 312 C 452.5573 312 449.0013 317.5 449.0013 323 C 449.0013 328.5 459.66926 341.3333 459.66926 341.3333 Z M 459.66797 328.6167 L 459.66797 328.6167 C 456.4093 328.6167 453.7682 325.9511 453.7682 322.6667 C 453.7682 319.3823 456.4093 316.7167 459.66797 316.7167 C 462.92466 316.7167 465.56777 319.3823 465.56777 322.6667 C 465.56777 325.9511 462.92466 328.6167 459.66797 328.6167 Z" fill="#007b4e" stroke="#007b4e" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></svg>'
  const informations = $('#google_maps_data').val() || '{}'
  const parsedInfo = JSON.parse(informations)
  const headquarterGeopoint = parsedInfo.headquartes_geo

  if (
    !headquarterGeopoint ||
    !headquarterGeopoint[0].lat ||
    !headquarterGeopoint[0].lng
  ) { return }

  const styledMapType = getStyledMapType(parsedInfo.style)

  const headquarterMap = new google.maps.Map(
    document.getElementById('headquarters_map'),
    {
      zoom: 13,
      center: {
        lat: headquarterGeopoint[0].lat,
        lng: headquarterGeopoint[0].lng
      },
      gestureHandling: 'none',
      zoomControl: false,
      disableDefaultUI: true,
      draggableCursor: 'default'
    }
  )

  let infowindow = new google.maps.InfoWindow({})

  headquarterMap.mapTypes.set('styled_map', styledMapType)
  headquarterMap.setMapTypeId('styled_map')
  headquarterGeopoint.forEach((geoPoint) => {
    const marker = new google.maps.Marker({
      title: 'Test',
      position: { lat: geoPoint.lat, lng: geoPoint.lng },
      map: headquarterMap,
      clickable: true,
      icon: {
        url:
          'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svgMarker),
        scaledSize: new google.maps.Size(32, 32)
      }
    })

    marker.addListener('click', () => {
      infowindow.close()

      infowindow = new google.maps.InfoWindow({
        content: getOfficeInfo(geoPoint)
      })

      infowindow.open(headquarterMap, marker)
    })
  })
}

function getStyledMapType (customStyle) {
  try {
    return new google.maps.StyledMapType(JSON.parse(customStyle), {
      name: 'Styled Map'
    })
  } catch (error) {
    return new google.maps.StyledMapType([], { name: 'Styled Map' })
  }
}

function getOfficeInfo (geoPoint) {
  const svgIcon = `<svg style="display: inline-block; margin-bottom: 6px; margin-right: 9px;" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M15.0001 1.61102L1.61133 7.34879" stroke="#007B4E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M9.26221 14.9998L15 1.61102" stroke="#007B4E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M1.61133 7.349L7.34909 9.26159L9.26235 15" stroke="#007B4E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>`
  const address = geoPoint && geoPoint.address
  const lat = geoPoint && geoPoint.lat
  const lng = geoPoint && geoPoint.lng
  const officeInfo = `
    <div class="officeInfo">

      ${address
      ? `<h5>Adresse</h5>
      <p>${address}</p>`
      : ''
    }

      ${lat && lng ? `<p><a target="_blank" href="https://www.google.com/maps/@?api=1&map_action=map&center=${lat},${lng}"><span>${svgIcon}</span>Åpne i kart</a></p>`
      : ''
    }

    </div>
  `

  return officeInfo
}
